$grid-size: 8px;

$grey-800: rgba(0, 0, 0, 0.65);

$text-emphasize-color: #1890ff;

$danger-color: #ff0000;

$border-color: #ebedf5;

$hover-color: #f5f5f5;

$mobile-breakpoint: 800px;

$border-color: #f0f0f0;

@mixin overrideAntd {
    &#{&} {
        @content;
    }
}

@mixin fillable {
    :global(.fillable) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: $mobile-breakpoint) {
        @content;
    }
}

@mixin printer {
    @media print {
        @content;
    }
}

@mixin not-printable {
    @include printer {
        display: none !important;
    }
}

@mixin hideDatePickerNowButton {
    :global(.ant-picker-now) {
        display: none;
    }
}

@mixin oneLineEllipsis {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.team-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2px;
    padding: $grid-size;

    background-color: #ffffff;

    .content {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        min-width: 0;
        height: 50px;

        .team-container {
            width: 100%;

            .team {
                display: flex;

                .team-name {
                    margin-left: $grid-size;
                }
            }
        }

        .external-reference-info {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding-left: 32px;

            .external-reference-info-display-name {
                @include oneLineEllipsis;
            }

            .advance-team-button {
                margin-left: $grid-size;
            }
        }

        .action-select {
            width: 100%;
        }
    }
}
