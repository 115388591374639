$grid-size: 8px;

$grey-800: rgba(0, 0, 0, 0.65);

$text-emphasize-color: #1890ff;

$danger-color: #ff0000;

$border-color: #ebedf5;

$hover-color: #f5f5f5;

$mobile-breakpoint: 800px;

$border-color: #f0f0f0;

@mixin overrideAntd {
    &#{&} {
        @content;
    }
}

@mixin fillable {
    :global(.fillable) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: $mobile-breakpoint) {
        @content;
    }
}

@mixin printer {
    @media print {
        @content;
    }
}

@mixin not-printable {
    @include printer {
        display: none !important;
    }
}

@mixin hideDatePickerNowButton {
    :global(.ant-picker-now) {
        display: none;
    }
}

@mixin oneLineEllipsis {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.authentication-layout {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .content {
        position: relative;
        top: 10%;
        width: 70 * $grid-size;
        padding: $grid-size 2 * $grid-size;
        display: flex;
        flex-direction: column;

        .header {
            text-align: center;
            margin: 2 * $grid-size 0;
        }

        .ue-logo {
            align-self: flex-end;
            margin: 0 2 * $grid-size 2 * $grid-size 0;
        }

        .logo-container {
            display: flex;

            .logo {
                width: 60%;
                margin: 0 auto;
            }
        }

        .actions {
            display: flex;
            flex-direction: column;
            align-items: center;

            > * {
                margin-top: 2 * $grid-size;
            }
        }
    }
}
