$grid-size: 8px;

$grey-800: rgba(0, 0, 0, 0.65);

$text-emphasize-color: #1890ff;

$danger-color: #ff0000;

$border-color: #ebedf5;

$hover-color: #f5f5f5;

$mobile-breakpoint: 800px;

$border-color: #f0f0f0;

@mixin overrideAntd {
    &#{&} {
        @content;
    }
}

@mixin fillable {
    :global(.fillable) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: $mobile-breakpoint) {
        @content;
    }
}

@mixin printer {
    @media print {
        @content;
    }
}

@mixin not-printable {
    @include printer {
        display: none !important;
    }
}

@mixin hideDatePickerNowButton {
    :global(.ant-picker-now) {
        display: none;
    }
}

@mixin oneLineEllipsis {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.columns {
    display: grid;
    gap: $grid-size;
    grid-template-columns: repeat(auto-fit, 300px);

    .sports-field-column-header {
        height: 4rem;

        .sports-field-column-header-content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .unassigned-matches-column-header {
        height: auto;

        .unassigned-matches-column-header-content {
            width: 100%;
            padding-top: 10px;

            .unassigned-matches-label {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}

.time-picker-dropdown {
    @include hideDatePickerNowButton;
}
