@import "../../node_modules/antd/dist/antd.less";

@menu-width: 12.5rem;
@mobile-breakpoint: 800px;

.mobile(@content) {
    @media (max-width: @mobile-breakpoint) {
        @content();
    }
}

.displaced-by-menu() {
    padding-left: @menu-width;

    .mobile({
        padding-left: 0;
    });
}

.ant-btn-group {
    display: inline-flex;
}

.ant-card-head {
    font-weight: 600;

    .ant-card-head-title {
        padding-left: 0.5rem;

        text-align: initial;
    }
}

.ant-message {
    .displaced-by-menu();
}

.ant-modal-wrap {
    .displaced-by-menu();
}
