$grid-size: 8px;

$grey-800: rgba(0, 0, 0, 0.65);

$text-emphasize-color: #1890ff;

$danger-color: #ff0000;

$border-color: #ebedf5;

$hover-color: #f5f5f5;

$mobile-breakpoint: 800px;

$border-color: #f0f0f0;

@mixin overrideAntd {
    &#{&} {
        @content;
    }
}

@mixin fillable {
    :global(.fillable) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: $mobile-breakpoint) {
        @content;
    }
}

@mixin printer {
    @media print {
        @content;
    }
}

@mixin not-printable {
    @include printer {
        display: none !important;
    }
}

@mixin hideDatePickerNowButton {
    :global(.ant-picker-now) {
        display: none;
    }
}

@mixin oneLineEllipsis {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.season-select {
    min-width: 25 * $grid-size !important;
}

.select-label {
    display: flex;

    .season-name {
        flex: 0 1 auto;
        overflow: hidden;

        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .season-state {
        flex: 1 0 auto;
        margin-left: 0.5rem;
    }
}

.reorder-buttons {
    display: flex;
    justify-content: flex-end;
}

.expand-icon {
    width: 6px;
    height: 6px;

    background-color: $grey-800;
    border-radius: 50%;
}

.actions-cell {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-end;
}
