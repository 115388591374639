$grid-size: 8px;

$grey-800: rgba(0, 0, 0, 0.65);

$text-emphasize-color: #1890ff;

$danger-color: #ff0000;

$border-color: #ebedf5;

$hover-color: #f5f5f5;

$mobile-breakpoint: 800px;

$border-color: #f0f0f0;

@mixin overrideAntd {
    &#{&} {
        @content;
    }
}

@mixin fillable {
    :global(.fillable) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: $mobile-breakpoint) {
        @content;
    }
}

@mixin printer {
    @media print {
        @content;
    }
}

@mixin not-printable {
    @include printer {
        display: none !important;
    }
}

@mixin hideDatePickerNowButton {
    :global(.ant-picker-now) {
        display: none;
    }
}

@mixin oneLineEllipsis {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}

$content-color: #bebebe;

@mixin placeholder {
    background-color: $content-color;

    border-radius: 4px;
}

.app-bar {
    width: 100%;
    height: 3rem;

    padding: 0.5rem 1rem;

    background-color: #000000;

    .logo-placeholder {
        width: 6rem;
        height: 100%;

        @include placeholder;
    }
}

.content {
    display: grid;
    grid-gap: 2 * $grid-size;
    grid-template-columns: 2fr 1fr;
}

.label {
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;

    font-weight: bold;
    text-transform: uppercase;

    border-bottom: 1px solid $border-color;
}

.news-grid {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-rows: repeat(3, 14rem);
    grid-template-columns: repeat(3, 1fr);
}

.news-banner {
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.small-news-banner-container {
    grid-row: 1;
    grid-column: 3;
}

.large-news-banner-container {
    grid-row: 2;
    grid-column: 1 / span 2;
}

.tile-skeleton {
    @include placeholder;
}

.videos-grid {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-rows: repeat(4, 4rem);
    grid-template-columns: 1fr;
}

.right-column-banners {
    margin-top: 1rem;
}
