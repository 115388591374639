$grid-size: 8px;

$grey-800: rgba(0, 0, 0, 0.65);

$text-emphasize-color: #1890ff;

$danger-color: #ff0000;

$border-color: #ebedf5;

$hover-color: #f5f5f5;

$mobile-breakpoint: 800px;

$border-color: #f0f0f0;

@mixin overrideAntd {
    &#{&} {
        @content;
    }
}

@mixin fillable {
    :global(.fillable) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: $mobile-breakpoint) {
        @content;
    }
}

@mixin printer {
    @media print {
        @content;
    }
}

@mixin not-printable {
    @include printer {
        display: none !important;
    }
}

@mixin hideDatePickerNowButton {
    :global(.ant-picker-now) {
        display: none;
    }
}

@mixin oneLineEllipsis {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.app-layout {
    height: 100vh;

    .expand-menu-button {
        margin: $grid-size 4 * $grid-size 0;

        @include not-printable;
    }

    .menu {
        @include mobile {
            position: fixed;
            top: 0;
            bottom: 0;
            z-index: 10000;

            &:global(.ant-layout-sider-collapsed) {
                visibility: hidden;
            }
        }

        @include not-printable;
    }

    .logo-container {
        padding: $grid-size 6 * $grid-size;
    }

    .content {
        @include printer {
            display: block;
            width: auto;
            height: auto;
            overflow: visible !important;
        }
    }
}
