$grid-size: 8px;

$grey-800: rgba(0, 0, 0, 0.65);

$text-emphasize-color: #1890ff;

$danger-color: #ff0000;

$border-color: #ebedf5;

$hover-color: #f5f5f5;

$mobile-breakpoint: 800px;

$border-color: #f0f0f0;

@mixin overrideAntd {
    &#{&} {
        @content;
    }
}

@mixin fillable {
    :global(.fillable) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: $mobile-breakpoint) {
        @content;
    }
}

@mixin printer {
    @media print {
        @content;
    }
}

@mixin not-printable {
    @include printer {
        display: none !important;
    }
}

@mixin hideDatePickerNowButton {
    :global(.ant-picker-now) {
        display: none;
    }
}

@mixin oneLineEllipsis {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.match-item {
    display: flex;
    margin: 2px;
    padding: $grid-size;

    background-color: #ffffff;

    .date-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 2rem;

        .conflicting-match-icon {
            margin-top: 2px;

            color: $danger-color;
            font-size: 1.25rem;
        }
    }

    &.conflicting-match-highlight {
        outline: 1px solid $danger-color;
    }

    .highlighted-team-name {
        font-weight: bold;
        text-decoration: underline;
    }

    .teams-and-tags {
        display: flex;
        flex-direction: column;

        .tag {
            width: min-content;
            margin-top: 4px;
        }
    }
}
