$grid-size: 8px;

$grey-800: rgba(0, 0, 0, 0.65);

$text-emphasize-color: #1890ff;

$danger-color: #ff0000;

$border-color: #ebedf5;

$hover-color: #f5f5f5;

$mobile-breakpoint: 800px;

$border-color: #f0f0f0;

@mixin overrideAntd {
    &#{&} {
        @content;
    }
}

@mixin fillable {
    :global(.fillable) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: $mobile-breakpoint) {
        @content;
    }
}

@mixin printer {
    @media print {
        @content;
    }
}

@mixin not-printable {
    @include printer {
        display: none !important;
    }
}

@mixin hideDatePickerNowButton {
    :global(.ant-picker-now) {
        display: none;
    }
}

@mixin oneLineEllipsis {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.header-more {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
