$grid-size: 8px;

$grey-800: rgba(0, 0, 0, 0.65);

$text-emphasize-color: #1890ff;

$danger-color: #ff0000;

$border-color: #ebedf5;

$hover-color: #f5f5f5;

$mobile-breakpoint: 800px;

$border-color: #f0f0f0;

@mixin overrideAntd {
    &#{&} {
        @content;
    }
}

@mixin fillable {
    :global(.fillable) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: $mobile-breakpoint) {
        @content;
    }
}

@mixin printer {
    @media print {
        @content;
    }
}

@mixin not-printable {
    @include printer {
        display: none !important;
    }
}

@mixin hideDatePickerNowButton {
    :global(.ant-picker-now) {
        display: none;
    }
}

@mixin oneLineEllipsis {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.header {
    display: flex;
    justify-content: space-between;

    &.-reverse {
        flex-direction: row-reverse;

        .team {
            flex-direction: row-reverse;
        }
    }

    .team {
        display: flex;
    }
}

.row {
    display: flex;
    justify-content: space-between;

    &.-reverse {
        flex-direction: row-reverse;
    }

    .player {
        display: flex;

        .shirt-number {
            display: inline-block;
            width: 2rem;
        }

        .goalkeeper {
            width: 2rem;
            margin-right: 0.35rem;
        }

        &.-reverse {
            flex-direction: row-reverse;

            .shirt-number {
                text-align: right;
            }

            .goalkeeper {
                margin-right: 0;
                margin-left: 0.45rem;
                padding-left: 0.25rem;

                text-align: right;
            }
        }
    }
}
