$grid-size: 8px;

$grey-800: rgba(0, 0, 0, 0.65);

$text-emphasize-color: #1890ff;

$danger-color: #ff0000;

$border-color: #ebedf5;

$hover-color: #f5f5f5;

$mobile-breakpoint: 800px;

$border-color: #f0f0f0;

@mixin overrideAntd {
    &#{&} {
        @content;
    }
}

@mixin fillable {
    :global(.fillable) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: $mobile-breakpoint) {
        @content;
    }
}

@mixin printer {
    @media print {
        @content;
    }
}

@mixin not-printable {
    @include printer {
        display: none !important;
    }
}

@mixin hideDatePickerNowButton {
    :global(.ant-picker-now) {
        display: none;
    }
}

@mixin oneLineEllipsis {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}

$page-width: 110 * $grid-size;

.page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5 * $grid-size 0;

    @include mobile {
        padding: $grid-size;
    }

    &.-wide {
        margin: 0 2 * $grid-size;
    }

    .card {
        width: $page-width;
        max-width: 100%;
    }

    &.-wide .card {
        width: 100%;
    }

    .action-button {
        width: 100%;

        @include not-printable;
    }

    .header {
        width: $page-width;
        max-width: 100%;
        background-color: white;
        border: 1px solid $border-color;
        border-bottom: none;

        @include not-printable;
    }

    &.-wide > .header {
        width: 100%;
    }

    .header-skeleton {
        padding: $grid-size 2 * $grid-size;
    }
}
